import { createMuiTheme } from "@material-ui/core/styles";
import { uiColors } from "./colors";

import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});


// Override default theme settings
export default createMuiTheme({
  overrides: {
    MuiFormControlLabel: {
      label: {
        color: uiColors.primary,
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "21px",
        position: 'relative',
        transform: 'scale(1.0)'
      }
    },
    MuiInput: {
      error: {
        backgroundColor: 'rgba(216, 76, 77, 0.1)',
        borderBottomColor: `${uiColors.error} !important`
      },
      root: {
        backgroundColor: uiColors.white,
        borderBottom: `2px solid transparent`,
        borderRadius: '4px',
        boxShadow: "0 2px 9px 0 rgba(24,45,75,0.1)",
        color: uiColors.primary,
        fontFamily: 'Lato',
        fontSize: "16px",
        lineHeight: "24px",
        minHeight: "24px",
        padding: "8px",
        transition: '200ms all ease',
        ['&:disabled']: {
          backgroundColor: '#eee'
        },
        ['&:hover']: {
          borderBottomColor: uiColors.background2
        },
        ['&:focus']: {
          borderBottomColor: uiColors.background2
        },
        ['&:active']: {
          borderBottomColor: uiColors.background2
        },
        ['&.completed']: {
          borderBottomColor: uiColors.background2
        }
      }
    }
  },
  palette: {
    primary: { main: uiColors.primary },
    secondary: { main: uiColors.secondary },
    text: {
      primary: uiColors.primary,
      secondary: uiColors.secondary
    }
  },
  props: {
    MuiInput: {
      disableUnderline: true,
    },
    MuiInputLabel: {
      shrink: true,
      style: {
        color: uiColors.primary,
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "21px",
        position: 'relative',
        transform: 'scale(1.0)'
      }
    }
  },
  typography: {
    // Body text  
    body1: {
      fontFamily: "Lato",
      fontSize: "21px",
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: "24px",

      [breakpoints.up('md')]: {
        fontSize: "24px",
        lineHeight: "32px"
      }
    },
    // Introduction
    body2: {
      fontFamily: "Lato",
      fontSize: "16px",
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: "24px"
    },
    button: {
      fontFamily: 'Merriweather',
      fontSize: "16px",
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: "24px",
      textTransform: "none"
    },
    // Caption
    caption: {
      fontFamily: "Merriweather",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    // H1
    h1: {
      fontFamily: "Merriweather",
      fontSize: "32px",
      fontWeight: 900,
      letterSpacing: 0,
      lineHeight: "40px",
      [breakpoints.up('md')]: {
        fontSize: "36px"
      }
    },
    // H2
    h2: {
      fontFamily: "Lato",
      fontSize: "32px",
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: "40px",
      [breakpoints.up('md')]: {
        fontSize: "32px",
        lineHeight: "40px"
      }
    },
    // H3
    h3: {
      fontFamily: "Merriweather",
      fontSize: "21px",
      fontWeight: 900,
      letterSpacing: 0,
      lineHeight: "28px",
      [breakpoints.up('md')]: {
        fontSize: "24px",
        lineHeight: "32px"
      }
    },
    // H4
    h4: {
      fontFamily: "Lato",
      fontSize: "18px",
      fontWeight: 700,
      letterSpacing: 0.1,
      lineHeight: "24px",
      textTransform: "uppercase",
      [breakpoints.up('md')]: {
        fontSize: "21px",
        lineHeight: "32px"
      }
    },
    h5: {
      fontFamily: "Lato",
      fontSize: "16px",
      fontWeight: 700,
      letterSpacing: 0.2,
      lineHeight: "21px",
      textTransform: "uppercase",
      [breakpoints.up('md')]: {
        lineHeight: "24px"
      }
    },
    h6: {
      fontFamily: "Lato",
      fontSize: "14px",
      fontWeight: 900,
      letterSpacing: 0.4,
      lineHeight: "16px",
      textTransform: "uppercase",
      [breakpoints.up('md')]: {
        lineHeight: "20px"
      }
    },
    overline: {
      fontFamily: "Lato",
      fontSize: "12px",
      fontWeight: 400,
      letterSpacing: 0.2,
      lineHeight: "16px",
      textTransform: 'uppercase'
    },
    subtitle1: {
      fontFamily: "Merriweather",
      fontSize: "18px",
      fontWeight: 200,
      letterSpacing: 0,
      lineHeight: "32px",
      [breakpoints.up('md')]: {
        fontSize: "21px"
      }
    },
    subtitle2: {
      fontFamily: "Lato",
      fontSize: "15px",
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: "18px",
      [breakpoints.up('md')]: {
        fontSize: "16px",
        lineHeight: "21px"
      }
    }
  }
});
