import { MuiThemeProvider } from "@material-ui/core/styles";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { theme, ThemeProvider } from './styled';

import('./analytics');

import './fonts.css';
import './typography.css';

import registerServiceWorker from './registerServiceWorker';

import loadable from "@loadable/component";
// import { CircularProgress } from "@material-ui/core";

// const logo = require('src/assets/images/logo_trans_bg.png');

const LoadableApp = loadable(() => import("./App"), {
  fallback: (<React.Fragment />)
});


ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <LoadableApp />
    </ThemeProvider>
  </MuiThemeProvider>,
  document.getElementById('root') as HTMLElement
);

registerServiceWorker();

// Former fallback
//
// <div style={{
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   justifyContent: 'center',
//   padding: '2rem'
// }}>
//   <CircularProgress size={24} />
//   <img src={logo} style={{ maxWidth: '250px', marginTop: '20px' }} />
//   </div>
