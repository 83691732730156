export const uiColors: IDVColorsUI = {
  accountMenu: '#F4F7FA',
  alert: "#E79640",
  background1: '#B3CBDA',
  background2: '#94CCBC',
  background3: '#E0E9F1',
  background4: '#D3EAE3',
  background5: '#F4F7FA',
  backgroundDrawer: '#EFF8F5',
  backgroundMain: '#FAFAFA',
  backgroundReceipt: "#0A6B82",
  backgroundComoveHeader: '#182D4B',
  backgroundComoveEditing: "#E0E9F1",
  backgroundComoveHeaderSuccess: "#90C0AF",
  backgroundVoucher: "#90C0AF",
  backgroundVoucherForm: "#EFF8F5",
  complete: "#EFF8F5",
  detail1: '#FAA619',
  detail2: '#3CB7B2',
  error: '#CF4647',
  moveHistory: '#F4F7FA',
  primary: '#182D4B',
  secondary: "#005468",
  selectionTextBlock: "rgba(170,216,203,0.15)",
  success: "#6FAA4B",
  white: '#FFF',
}

interface IDVColorsUI {
  accountMenu: string;
  background1: string;
  background2: string;
  background3: string;
  background4: string;
  background5: string;
  backgroundDrawer: string;
  backgroundMain: string;
  backgroundReceipt: string;
  backgroundComoveHeader: string;
  backgroundComoveHeaderSuccess: string;
  backgroundComoveEditing: string;
  backgroundVoucher: string;
  backgroundVoucherForm: string;
  success: string;
  primary: string;
  secondary: string;
  detail1: string;
  detail2: string;
  white: string;
  complete: string;
  alert: string;
  error: string;
  moveHistory: string;
  selectionTextBlock: string;
}
