import { Theme } from "@material-ui/core";

import theme from "src/theme";

import * as styledComponents from "styled-components";

import { ThemedStyledComponentsModule } from "styled-components";

import { uiColors } from "./colors";

const styled = {
  ThemeProvider: styledComponents.ThemeProvider,
  css: styledComponents.css,
  default: styledComponents.default,
  keyframes: styledComponents.keyframes,
} as ThemedStyledComponentsModule<Theme>;

// tslint:disable-next-line:no-empty-interface
export interface IThemeInterface { }

export default styled.default;

const { css, keyframes, ThemeProvider } = styled;
const colors = uiColors;

export { colors, uiColors, css, keyframes, ThemeProvider, theme };

